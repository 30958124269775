@media only screen and (min-width: 768px) {
  .carousel {
    margin: 100px !important;
  }
  .carousel .card img {
    /* padding: 5px; */
    /* -webkit-box-shadow: 9px 10px 29px -10px rgba(0,0,0,0.75);
        -moz-box-shadow: 9px 10px 29px -10px rgba(0,0,0,0.75);
        box-shadow: 9px 10px 29px -10px rgba(0,0,0,0.75); */
    display: block;
    margin: auto;
    width: 200px;
    height: 200px;
    /* background-size: cover; */
    /* float: left; */
    object-fit: scale-down;
  }
  /* .carousel .card {
        margin: 30px;
    } */
  .carousel .card {
    padding: 30px;
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    border: 0;
    margin-left: 15px;
    margin-right: 15px;
    height: 33rem;
  }
  .card-img-top {
    text-align: center;
    max-width: 30rem;
  }

  .divx {
    /* margin:100px!important; */
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    /* margin-left: 10px!important; */
    /* margin-right: ; */
  }
  .divx .card-body {
    max-height: 30rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .carousel {
    margin: 20px !important;
  }
  .carousel .card img {
    display: block;
    margin: auto;
    width: 200px;
    height: 200px;
    background-size: cover;
    float: left;
    object-fit: scale-down;
  }
  .carousel .card {
    padding: 15px;
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    border: 0;
    width: 100% !important;
    height: 30rem;
  }
  .card-img-top {
    max-width: 20rem;
  }
  /* .carousel .card{
        max-width: 20rem!important;
    } */
  .divx {
    /* margin:100px!important; */
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    /* margin-left: 10px!important; */
    /* margin-right: ; */
  }
  .divx .card-body {
    max-height: 30rem !important;

    /* margin: 10px; */
  }
  .card {
    max-width: 80rem !important;
  }
}

.divx .h5 {
  font-size: 1.1rem !important;
}
