@media only screen and (min-width: 768px) {
  #logo_premium {
    width: 40vh;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 120px;
  }
}

@media only screen and (max-width: 767px) {
  #logo_premium {
    width: 40vh;
  }
}
