@media only screen and (max-width: 767px) {
  .location-picker {
    /* height: 30vh!important; */
    margin: 15px;
    margin-top: 130px;
    padding: 30px;
    background-color: rgba(173, 171, 171, 0.2);
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
  }

  .location-picker .leaflet-container {
    height: 45vh;
    width: 100%;
    margin-bottom: 30px;
    z-index: 1;
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
  }
  .heading_inregistrare {
    text-align: center;
    font-weight: 700;
    color: #888;
  }

  .subheading_inregistrare {
    text-align: center;
    font-weight: 700;
    color: #888;
  }
  .form-check-label {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .location-picker {
    /* height: 30vh!important; */
    margin: 30px;
    margin-top: 130px;
    padding: 50px;
    background-color: rgba(173, 171, 171, 0.2);
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
  }

  .location-picker .leaflet-container {
    height: 90%;
    width: 100%;
    z-index: 1;
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
  }
  .heading_inregistrare {
    text-align: center;
    font-weight: 700;
    color: #888;
  }

  .subheading_inregistrare {
    text-align: center;
    font-weight: 700;
    color: #888;
  }
}
