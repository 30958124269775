.div_col1_lp p {
  color: #888;
}

#myFooter p {
  color: #888 !important;
}

.tabel_produse {
  max-height: 350px;
  overflow-y: auto;
}

.div_col1_lp p {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  #logo_producator {
    max-height: 200px;
  }
  .col2_logo_prod {
    /* margin: 45px; */
    margin-top: 15vh;
  }
  #poza_producator {
    max-height: 400px;
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
  }
  #logo_hplr {
    max-height: 50px;
    margin-left: 5px;
  }
  .col2_poza_prod {
    margin-top: 5%;
  }
  h2,
  .testt {
    display: inline;
  }

  .testt {
    margin-left: 10px;
  }
  .products .card {
    margin: 30px;
  }

  .products .card img {
    /* padding: 5px; */
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
  }

  .card-img-top {
    max-width: 20rem;
  }
  .products .card {
    max-width: 20rem !important;
  }
  .products {
    /* -webkit-box-shadow: 9px 10px 29px -10px rgba(0,0,0,0.75);
        -moz-box-shadow: 9px 10px 29px -10px rgba(0,0,0,0.75);
        box-shadow: 9px 10px 29px -10px rgba(0,0,0,0.75); */
    background-color: rgb(249, 249, 249);
    margin: 20px;
    padding-top: 40px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  /* .card{
        margin-left:40px!important;
        margin-right: 40px!important;
    } */
  #logo_producator {
    max-height: 80px;
    margin-bottom: 20px;
  }
  #poza_producator {
    margin-top: 20px;
    max-height: 30vh;
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
  }
  #logo_hplr {
    max-height: 50px;
    margin-left: 5px;
  }
  .hideOnMobile {
    display: none;
  }
  h2,
  .testt {
    display: inline;
  }

  .testt {
    margin-left: 10px;
  }

  .products .card {
    margin: 10px;
  }

  .products .card img {
    /* padding: 5px; */
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
  }

  .card-img-top {
    max-width: 300px;
  }
  .card {
    max-width: 14rem !important;
  }
  .products {
    /* -webkit-box-shadow: 9px 10px 29px -10px rgba(0,0,0,0.75);
        -moz-box-shadow: 9px 10px 29px -10px rgba(0,0,0,0.75);
        box-shadow: 9px 10px 29px -10px rgba(0,0,0,0.75); */
    background-color: rgb(249, 249, 249);
    padding-bottom: 40px;
  }
}

.github-fork-ribbon:before {
  background-color: green !important;
}
