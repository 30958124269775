@import "LandingPage.css";
@import "PaginaProducator.css";
@import "Register.css";
@import "Premium.css";
.poza_montan {
  height: 10vh;
}
.main-container {
  height: 70vh;
}
.info-window .github-fork-ribbon {
  font-size: 8px;
}

/*CSS GENERAL*/

.info-window a {
  color: white;
}
.termeni {
  margin-top: 15vh;
  margin-bottom: 50px;
  margin-left: 50px;
  margin-right: 50px;
}

/* TODO: add some pretty styling here!!! */

* {
  scrollbar-width: thin;
  scrollbar-color: #9b6cfc rgb(255, 255, 255);
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
  background-color: white;
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  padding-left: 10px;
  background-color: #9b6cfc;
  border-radius: 5px;
}

.scrollable {
  overflow-y: scroll;
}

.nav-tabs {
  justify-content: flex-end;
}

.navbar-style {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.55) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #f7f7f7;
  /* background-color: #f4f4f4; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 150;
}

.nav_inscriere {
  color: #9b6cfc !important;
}

.info-window label {
  font-weight: 700;
  font-size: 1.2em;
}

:root {
  --primary: #9b6cfc !important;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #9b6cfc87;
}

.btn-primary:hover {
  background-color: rgb(142, 86, 253) !important;
}

.btn-primary {
  background-color: #9b6cfc !important;
  border-color: #9b6cfc !important;
  color: white !important;
}

.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem #9b6cfc87;
}

.btn-outline-primary:hover {
  background-color: rgb(177, 138, 255) !important;
  color: white;
}

.btn-outline-primary:active {
  background-color: rgb(137, 78, 253) !important;
  color: white;
}

.btn-outline-primary.active {
  background-color: rgb(137, 78, 253) !important;
  color: white !important;
  box-shadow: 0 0 0 0.2rem #9b6cfc87 !important;
}

.btn-outline-primary {
  background-color: white;
  border-color: #9b6cfc !important;
  color: #9b6cfc;
}

.button-icon {
  padding: 15px;
  font-size: 3em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.btn-outline-primary div {
  margin-top: 5px;
  font-size: 0.25em;
}

.listing-close {
  position: absolute;
  z-index: 100;

  font-size: 1.2em;
  right: 0;
}

.listing_container h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
}

.listing_container p {
  color: black;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  background-color: rgb(212, 220, 255);
}

.list-group-item-info {
  background-color: rgb(201, 211, 254);
}

.rounded_image {
  float: left;
  height: 55px;
  margin-right: 10px;
}

.small_rounded_image {
  height: 2em;
  margin-left: 1em;
  margin-right: 1em;
}

.logo {
  height: 55px;
}

.reg_form {
  overflow-y: scroll;
  height: 350px;
  overflow-x: hidden;
}

.icon-button-size {
  height: 1em;
}

.filter_text {
  text-align: center;
}

.marker_description {
  text-align: justify;
}
/*  */

.report {
  width: 45px;
}

.card-body {
  max-height: 15em;
  overflow-y: scroll;
}

.listing_option {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.margin_top {
  margin-top: 6rem !important;
}

.align-center {
  text-align: center;
}

.listing_option a {
  color: #9b6cfc;
}
/*CSS ECRANE MARI*/

@media only screen and (min-width: 768px) {
  .margin_but_web {
    margin-left: 10px;
  }
  body {
    /* overflow: hidden; */
  }
  .map-container {
    /* height: 80vh; */
  }
  .formular {
    width: 100%;
    border: 0;
    padding-bottom: 15px;
    height: 80vh;
  }
  .listing_container {
    /* margin-top:3rem!important; */
    height: 70vh;
    overflow-x: hidden;
    /* padding-bottom: 45px; */
  }
  .filter_container {
    height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 25px;
  }
  .main-container .leaflet-container {
    height: 75vh;
    width: 100%;
    z-index: 1;
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
  }
  .filter_class {
    margin-top: 3rem !important;
  }
  .info-window {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 16em;
    max-height: 60vh;
    overflow: auto;
    /* height: 50vh;
        overflow-y: scroll; */
  }
  .tests {
    visibility: hidden;
  }
  .map-message {
    /* position: absolute; */
    z-index: 600;
    text-align: center;
  }
  .harta .filter_tab_mobile {
    display: none;
  }
  .leaflet-control-zoom {
    /* display: none; */
  }
}

@media only screen and (max-width: 767px) {
  /* html, body {
        overflow-x: hidden;
      }
      body {
        position: relative;
      } */
  .margin_but_web {
    margin-top: 5px;
  }
  .mt_mobile {
    margin-top: 10px;
  }
  .no-sm-padding {
    padding: 0;
  }

  .no-sm-display {
    display: none;
  }

  .legend-show {
    bottom: 20px;
    left: 30px;

    width: 55px;
    height: 55px;

    font-size: 3em;
    transition: all 30ms ease;
  }

  .legend-show-text {
    position: absolute;
    font-size: 0.45em;
    bottom: 50px;
    margin-left: 2px;
  }
  .map-container {
    height: 70vh;
  }
  .leaflet-container {
    height: 70vh;
    width: 100%;
    z-index: 1;
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
  }
  .formular {
    width: 100%;
    border: 0;
    padding-bottom: 15px;
    height: 70vh;
  }
  .listing_container {
    height: 60vh;
    overflow-x: hidden;
    /* padding-bottom: 45px; */
  }
  .filter_container {
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 25px;
  }
  .filter_class {
  }
  .info-window {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 15em;
    max-height: 40vh;
    overflow: auto;
    padding: 5px;
  }
  .web_col {
    display: none;
  }
  .map-message {
    text-align: center;
    /* position: absolute; */
    z-index: 600;
  }
}

.sterge_filtre {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 10px;
  /* margin-bottom: 40px; */
}

.search_box {
  margin-left: 10%;
  /* margin-right: 10%; */
  margin-top: 10px;
}

.page-item.active .page-link {
  background-color: #9b6cfc !important;
  border-color: #9b6cfc !important;
}

.margins_top {
  margin-top: 20vh !important;
  margin-bottom: 10vh !important;
}
