@font-face {
  font-family: "CustomFont";
  src: url("Alegreya-Regular.ttf");
  /* src: url("./fonts/raleway_thin-webfont.woff") format("woff"),
    url("./fonts/raleway_thin-webfont.ttf") format("opentype"),
    url("./fonts/raleway_thin-webfont.svg") format("svg"); */
}

/*CSS LP*/
* {
  font-family: CustomFont;
}

.first_row_buttons .button_inscriere {
  color: #9b6cfc;
}

.first_row_buttons button {
  margin: 2px;
}

.lp-containter p {
  color: #888888;
}
.firs_row_lp {
  background-color: rgb(249, 249, 249);
  padding-top: 50px;
}

.third_row_lp {
  background-color: rgb(249, 249, 249);
}
.fourth_row_lp img {
  width: 50px;
  /* margin-right: 10px; */
}

.fourth_row_lp {
  /* padding-right: 10px; */
  text-align: center;
  /* width: 70vw; */
}

@media only screen and (min-width: 768px) {
  .div_col1_lp {
    align-content: center;
    margin: 65px;
    padding: 30px;
  }
  .div_col1_lp h4 {
    margin-bottom: 20px;
    color: #9b6cfc;
  }
  .div_col1_lp h2 {
    margin-bottom: 20px;
  }
  .div_col2_lp {
    margin: 45px;
  }
  .first_row_lp {
    /* background-color: rgb(228, 227, 227); */
  }

  .lp-containter .leaflet-container {
    height: 55vh;
    width: 100%;
    z-index: 1;
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
  }
  .fourth_row_lp {
    text-align: center;
    margin: 40px;
  }
  .fourth_row_lp img {
    width: 100px;
  }
  .despre_noi {
    margin: 50px;
  }
  #logo_lp {
    width: 30vh;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 120px;
  }
  .echipa .poze_echipa {
    height: 40vh;
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    margin-bottom: 20px;
  }
  .echipa {
    text-align: center;
    padding-bottom: 40px;
  }
  .echipa .social {
    height: 40px;
  }
}
.footer_lp {
  background-color: #9b6cfc;
}
.footer_lp a,
.footer_lp p {
  color: white;
}

.title_echipa {
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .div_col1_lp {
    align-content: center;
    padding: 40px;
    margin-top: 20px;
  }
  .div_col1_lp h4 {
    margin-bottom: 20px;
    color: #9b6cfc;
  }
  .div_col1_lp h2 {
    margin-bottom: 20px;
  }
  .div_col2_lp {
    margin: 25px;
    text-align: center;
  }
  .first_row_lp {
    /* background-color: rgb(228, 227, 227); */
  }

  .lp-containter .leaflet-container {
    height: 50vh;
    width: 100%;
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    z-index: 1;
  }

  .third_row_lp {
    text-align: center;
    margin: 20px;
  }

  .third_row_lp img {
    width: 100px;
    margin-bottom: 10px;
  }
  #logo_lp {
    width: 30vh;
  }
  .fourth_row_lp {
    text-align: center;
    margin: 40px;
  }
  .fourth_row_lp img {
    width: 60px;
  }
  .echipa .poze_echipa {
    height: 30vh;
    margin-bottom: 15px;
    -webkit-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 10px 29px -10px rgba(0, 0, 0, 0.75);
  }
  .leaflet-top.leaflet-left {
    display: none;
  }
  .echipa {
    text-align: center;
  }
  .echipa .social {
    height: 40px;
    margin-bottom: 20px;
  }
}

#myFooter {
  background-color: rgb(249, 249, 249);
  color: white;
  padding-top: 30px;
}

#myFooter .footer-copyright {
  background-color: #333333;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}

#myFooter .row {
  margin-bottom: 60px;
}

#myFooter .navbar-brand {
  margin-top: 45px;
  height: 65px;
}

#myFooter .footer-copyright p {
  margin: 10px;
  color: #ccc;
}

#myFooter ul {
  list-style-type: none;
  padding-left: 0;
  line-height: 1.7;
}

#myFooter h5 {
  font-size: 18px;
  color: rgb(216, 216, 216);
  font-weight: bold;
  margin-top: 30px;
}

#myFooter h2 a {
  font-size: 50px;
  text-align: center;
  color: #fff;
}

#myFooter a {
  color: #d2d1d1;
  text-decoration: none;
}

#myFooter a:hover,
#myFooter a:focus {
  text-decoration: none;
  color: #bdbcbd;
}

#myFooter .social-networks {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 16px;
}

#myFooter .social-networks a {
  font-size: 32px;
  color: #bdbcbd;
  padding: 10px;
  transition: 0.2s;
}

#myFooter .social-networks a:hover {
  text-decoration: none;
}

#myFooter .facebook:hover {
  color: #0077e2;
}

#myFooter .google:hover {
  color: #ef1a1a;
}

#myFooter .twitter:hover {
  color: #00aced;
}

#myFooter .btn {
  color: white;
  background-color: #d84b6b;
  border-radius: 20px;
  border: none;
  width: 150px;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  line-height: 25px;
}

#myFooter img {
  max-width: 250px;
}

@media screen and (max-width: 767px) {
  #myFooter {
    text-align: center;
  }
}

.pricingTable {
  padding: 35px 20px;
  margin: 0 10px;
  background: #f4f4f4;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease 0s;
}

.pricingTable .pricingTable-header {
  display: block;
  padding: 10px 30px 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #000;
  position: relative;
}
.pricingTable .pricingTable-header:before {
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #fff;
  box-shadow: inset 1px -5px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: -52px;
  transform: rotate(-110deg);
}
.pricingTable .title {
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  color: #9b6cfc;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.pricingTable .pricing-content {
  padding-left: 15px;
  margin: 0 0 20px 30%;
  text-align: left;
  position: relative;
}
.pricingTable .pricing-content:after {
  content: "";
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  box-shadow: inset 1px -5px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
  right: -45px;
  transform: rotate(110deg);
}
.pricingTable .price-value {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #9b6cfc;
  text-align: right;
  position: absolute;
  top: 0;
  left: -100%;
}
.pricingTable .price-value:after {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  box-shadow: inset 1px -5px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 2px;
  right: 45%;
  transform: rotate(-110deg);
}
.pricingTable .amount {
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.pricingTable .description {
  font-size: 14px;
  color: #4f4f4f;
  line-height: 22px;
  margin-bottom: 15px;
}
.pricingTable .pricing-content ul {
  padding: 0;
  margin: 0 0 0 15px;
}
.pricingTable .pricing-content ul li {
  font-size: 15px;
  color: #888 !important;
  line-height: 30px;
}
.pricingTable .pricingTable-signup {
  display: inline-block;
  padding: 10px 25px;
  background: #9b6cfc;
  font-size: 17px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 1px;
  border-radius: 30px 0 30px 30px;
  transition: all 0.3s ease 0s;
}
.pricingTable:hover .pricingTable-signup {
  border-radius: 30px 30px 30px 0;
}
.pricingTable.yellow .title {
  color: #e0ac1e;
}
.pricingTable.yellow .price-value,
.pricingTable.yellow .pricingTable-signup {
  background: #e0ac1e;
}
.pricingTable.green .title {
  color: #158163;
}
.pricingTable.green .price-value,
.pricingTable.green .pricingTable-signup {
  background: #158163;
}
@media only screen and (max-width: 1199px) and (min-width: 991px) {
  .pricingTable .amount {
    right: 5px;
  }
}
@media only screen and (max-width: 990px) {
  .pricingTable {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 360px) {
  .pricingTable .amount {
    right: 5px;
    font-size: 20px;
  }
}
